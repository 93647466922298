import { request, noTimeOutReq } from '@/utils/request.js'

//条件展示用户信息
export function getUserListApi (pageIndex, pageSize, queryUser) {
  return request({
    url: `/user/admin/user/query/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
    data: queryUser,
  })
}

//获取用户详情
export function getUserApi (userId) {
  return request({
    url: `/user/admin/user/${userId}/get`,
    method: 'post',
  })
}

//新增用户
export function addUserApi (addUser) {
  return request({
    url: `/admin/user/add`,
    method: 'post',
    data: addUser,
  })
}

//修改用户
export function updateUserApi (updateUser) {
  return request({
    url: `/admin/user/update`,
    method: 'post',
    data: updateUser,
  })
}

//删除用户
export function removeUserApi (idList) {
  return request({
    url: `/admin/user/delete`,
    method: 'post',
    data: idList,
  })
}

//重置用户密码
export function resetUserPassApi (userId) {
  return request({
    url: `/admin/user/password/reset/${userId}/update`,
    method: 'post',
  })
}

//修改用户状态,status禁用传0，启用传1
export function updateUserStatusApi (userId, status) {
  return request({
    url: `/admin/user/status/${userId}/${status}/update`,
    method: 'post',
  })
}

//获取筛选区县列表
export function getOrgAreaListApi () {
  return request({
    url: `/sys/org/area/root/get`,
    method: 'post',
  })
}

//获取筛选学校列表
export function getOrgSchoolListApi (getExamPlace, getSchool, sysOrgAreaId) {
  return request({
    url: `/sys/org/get/or`,
    method: 'post',
    params: { getExamPlace: getExamPlace, getSchool: getSchool, sysOrgAreaId: sysOrgAreaId },
  })
}

//获取筛选班级列表
export function getOrgClassListApi (sysOrgSchoolId) {
  return request({
    url: `/sys/org/school/class/school/${sysOrgSchoolId}/list`,
    method: 'post',
  })
}

//获取筛选角色列表
export function getOrgRoleListApi () {
  return request({
    url: `/sys/auth/role/list`,
    method: 'post',
  })
}

//获取配置文件的root地区 ，学校 或考点
export function getOrganizeListApi (getExamPlace, getSchool) {
  return request({
    url: `/sys/org/get/root/or`,
    method: 'post',
    params: { getExamPlace: getExamPlace, getSchool: getSchool },
  })
}
