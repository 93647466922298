<template>
  <div id="user-content">
    <el-row>
      <el-button
        v-throttle
        type="primary"
        icon="el-icon-circle-plus-outline"
        size="medium"
        @click="edit('add', '')"
        >添加</el-button
      >
      <!-- <el-upload
        style="display:inline-block;margin: 0 10px;"
        action="https://jsonplaceholder.typicode.com/posts/"
        :before-upload="handleBeforeUpload"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :limit="1"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :show-file-list="false"
        ref="uploadRef"
      >
        <el-button v-throttle  type="primary" icon="el-icon-upload2" size="medium">导入</el-button>
      </el-upload>-->
      <!-- <el-upload
        style="display:inline-block;margin:0 10px"
        :limit="1"
        action="https://jsonplaceholder.typicode.com/posts/"
        :http-request="uploadBtn"
        :show-file-list="false"
      >
        <el-button v-throttle  size="medium" type="primary" icon="el-icon-upload2">导入</el-button>
      </el-upload>-->
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="
          () => {
            this.$refs.uploadExcelCom.dialogBox = true
            this.uploadMsg = ''
          }
        "
        icon="el-icon-upload2"
        >导入</el-button
      >
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="downloadBtn"
        icon="el-icon-download"
        >下载导入模板</el-button
      >
      <el-button
        v-throttle
        type="danger"
        icon="el-icon-delete"
        size="medium"
        @click="batchRemove"
        >批量删除</el-button
      >
    </el-row>

    <el-row style="margin-top: 20px">
      <el-form :inline="true" :model="queryForm">
        <el-form-item label="姓名">
          <el-input
            v-model="queryForm.queryName"
            placeholder="请输入姓名"
            @input="nickNameChange"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="账号">
          <el-input
            v-model="queryForm.queryAccount"
            placeholder="请输入账号"
            @input="accountChange"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="区县">
          <el-select
            v-model="queryForm.querySysOrgAreaId"
            placeholder="请选择区县"
            @change="orgAreaChange"
            clearable
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="area in orgAreaOptions"
              :key="area.orgId"
              :label="area.orgName"
              :value="area.orgId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学校">
          <el-select
            v-model="queryForm.querySysOrgSchoolId"
            placeholder="请选择学校"
            @change="orgSchoolChange"
            clearable
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="school in orgSchoolOptions"
              :key="school.orgId"
              :label="school.orgName"
              :value="school.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            class="role-select-content"
            v-model="queryForm.querySysAuthRoleId"
            placeholder="请选择角色"
            @change="orgRoleChange"
            clearable
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="role in orgRoleOptions"
              :key="role.sysAuthRoleId"
              :label="role.name"
              :value="role.sysAuthRoleId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <el-select
            v-model="queryForm.status"
            placeholder="请选择状态"
            @change="orgStatusChange"
            clearable
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="status in orgStatusOptions"
              :key="status.value"
              :label="status.label"
              :value="status.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            icon="el-icon-refresh"
            @click="refresh"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <el-row>
      <el-table
        v-loading="loading"
        border
        ref="userTable"
        :data="userList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="nickName"
          label="姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="account"
          label="账号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="county" label="区县" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.orgList.length > 0
              ? scope.row.orgList[0].sysOrgAreaRegionName
              : '未知'
          }}</template>
        </el-table-column>
        <el-table-column prop="school" label="学校" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.orgList.length > 0
              ? scope.row.orgList[0].sysOrgSchoolName
              : '未知'
          }}</template>
        </el-table-column>
        <el-table-column
          prop="roleList"
          label="角色"
          show-overflow-tooltip
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column prop="status" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">禁用</span>
            <span v-else-if="scope.row.status == 1">启用</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="230px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              icon="el-icon-view"
              @click="edit('see', scope.row)"
              >查看</el-button
            >
            <el-button
              v-throttle
              size="mini"
              type="success"
              icon="el-icon-edit"
              @click="edit('edit', scope.row)"
              >编辑</el-button
            >

            <el-button
              v-throttle
              size="mini"
              type="warning"
              icon="el-icon-unlock"
              @click="resetUserPass(scope.row)"
              >重置密码</el-button
            >

            <el-button
              v-throttle
              v-if="scope.row.status == 0"
              size="mini"
              type="primary"
              icon="el-icon-open"
              @click="disabled(scope.row)"
              >启用</el-button
            >

            <el-button
              v-throttle
              v-if="scope.row.status == 1"
              size="mini"
              type="info"
              icon="el-icon-turn-off"
              @click="disabled(scope.row)"
              >禁用</el-button
            >

            <el-button
              v-throttle
              size="mini"
              type="danger"
              icon="el-icon-delete-solid"
              @click="remove(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>

    <el-row>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="768px"
        :before-close="handleClose"
        @close="closed"
      >
        <el-form
          :inline="true"
          :model="submitForm"
          :rules="rules"
          ref="submitForm"
          :disabled="submitFormDisabled"
          label-width="80px"
        >
          <el-form-item label="姓名" prop="nickName">
            <el-input
              v-model.trim="submitForm.nickName"
              autocomplete="off"
              class="input-content"
              placeholder="请输入姓名"
              maxlength="13"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="账号" prop="account">
            <el-input
              v-model.trim="submitForm.account"
              autocomplete="off"
              class="input-content"
              placeholder="请输入账号"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item
            label="机构"
            prop="organize"
            class="select-tree-content"
          >
            <div id="tree_box">
              <el-tree
                node-key="orgId"
                :data="organizeTreeList"
                :props="defaultProps"
                highlight-current
                :filter-node-method="filterNode"
                @check="checkOrganizeChange"
                @check-change="handleCheckChange"
                show-checkbox
                :default-expanded-keys="expandedList"
                :default-checked-keys="checkedTreeList"
                ref="organizeTree"
              ></el-tree>
            </div>
          </el-form-item>
          <el-form-item label="用户编码" prop="userCode">
            <el-input
              v-model.trim="submitForm.userCode"
              autocomplete="off"
              class="input-content"
              clearable
              placeholder="请输入用户编码"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item 
            label="科目" 
            style="position: absolute; right: 66px; top: 208px"
            prop="mainSysSubjectDicId"
          >
            <el-select 
              placeholder="请选择科目"
              style="width: 250px"
              v-model="submitForm.mainSysSubjectDicId"
            >
              <el-option 
                v-for="item in sysSubjectDicList" 
                :key="item.value" 
                :label="item.label" 
                :value="item.value"
              ></el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="角色" prop="roleIdList">
            <el-checkbox-group
              v-model="submitForm.roleIdList"
              @change="handleCheckedChange"
              size="medium"
            >
              <el-checkbox
                v-for="role in orgRoleOptions"
                :label="role.sysAuthRoleId"
                :key="role.sysAuthRoleId"
                >{{ role.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="状态" prop="status">
            <el-radio
              v-model="submitForm.status"
              :label="1"
              border
              size="medium"
              >启用</el-radio
            >
            <el-radio
              v-model="submitForm.status"
              :label="0"
              border
              size="medium"
              >禁用</el-radio
            >
          </el-form-item>

          <el-form-item label="性别" prop="gender">
            <el-radio
              v-model="submitForm.gender"
              :label="1"
              border
              size="medium"
              >男</el-radio
            >
            <el-radio
              v-model="submitForm.gender"
              :label="0"
              border
              size="medium"
              >女</el-radio
            >
          </el-form-item>

          <el-form-item label="手机" prop="phone">
            <el-input
              v-model.trim="submitForm.phone"
              autocomplete="off"
              class="input-content"
              clearable
              placeholder="请输入手机号"
              maxlength="11"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model.trim="submitForm.email"
              autocomplete="off"
              class="input-content"
              clearable
              placeholder="请输入邮箱"
              maxlength="18"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            v-throttle
            type="primary"
            @click="save('submitForm')"
            :disabled="confirmDisabled"
            :loading="confirmLoading"
          >
            {{ confirmLoading ? confirmLoadingButtonText : confirmButtonText }}
          </el-button>
          <el-button v-throttle @click="cancel('submitForm')">取 消</el-button>
        </div>
      </el-dialog>
    </el-row>
    <upload-excel-com
      @uploadFunc="uploadBtn"
      ref="uploadExcelCom"
      :uploadMsg="uploadMsg"
      :loading="uploadLoading"
    ></upload-excel-com>
  </div>
</template>

<script>
import { downloadAdmin, importExcelAdmin } from '@/api/importExport.js'
import {
  getUserListApi,
  getUserApi,
  addUserApi,
  updateUserApi,
  removeUserApi,
  resetUserPassApi,
  updateUserStatusApi,
  getOrgAreaListApi,
  getOrgSchoolListApi,
  getOrgRoleListApi,
  getOrganizeListApi,
} from '@/api/user/userManage.js'
import { isEmpty } from '@/utils/util.js'
export default {
  name: 'userManage',
  data() {
    const validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('姓名不能为空'))
      } else {
        callback()
      }
    }

    const validateAccount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('账号不能为空'))
      }
      if (!/[a-zA-Z0-9]{6,20}$/.test(value)) {
        callback(new Error('账号必须为6-20位字母或数字'))
      } else {
        callback()
      }
    }

    const validateOrganize = (rule, value, callback) => {
      let arr = this.$refs.organizeTree.getCheckedKeys()
      if (!arr || arr.length == 0) {
        callback(new Error('请选择组织'))
      } else {
        callback()
      }
    }

    return {
      selectValue: [],
      selectedValue: [],
      queryForm: {
        queryName: '',
        queryAccount: '',
        querySysOrgAreaId: '',
        querySysOrgSchoolId: '',
        querySubjectType: '',
        querySysAuthRoleId: '',
        status: '',
      },
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      userList: [],
      //开启遮罩层
      loading: false,
      //是否开启弹出框
      dialogVisible: false,
      //弹出框title
      title: '',
      //弹出框表单数据(提交后台)
      submitForm: {
        nickName: '',
        account: '',
        userCode: '',
        county: '',
        school: '',
        role: '',
        status: 1,
        gender: 1,
        phone: '',
        email: '',
        sysOrgDTOList: [],
        roleIdList: [],
        mainSysSubjectDicId: '',
      },
      selectedSysOrgDTOList: [],
      updateUser: {},
      //表单验证
      rules: {
        nickName: [
          {
            required: true,
            validator: validateName,
            trigger: ['blur', 'change'],
          },
        ],
        mainSysSubjectDicId: [{ required: true, message: '请选择科目', trigger: 'change' }],
        account: [
          {
            required: true,
            validator: validateAccount,
            trigger: ['blur', 'change'],
          },
        ],
        userCode: [
          {
            required: true,
            message: '用户编码不能为空',
            trigger: ['blur', 'change'],
          },
        ],
        // organize: [{ required: true, validator: validateOrganize, trigger: "change" }],
        school: [{ required: true, message: '请选择学校', trigger: 'change' }],
        // roleIdList: [
        //   { type: 'array', required: true, message: '请至少选择一个角色', trigger: 'change' }
        // ],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          // { required: true, message: '邮箱地址不能为空', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ],
      },
      //table已勾选项
      selectedList: [],
      //是否禁用弹出框表单内容
      submitFormDisabled: false,
      //是否禁用弹出框确认按钮
      confirmDisabled: false,
      //确认按钮是否加载
      confirmLoading: false,
      confirmLoadingButtonText: '保存中...',
      confirmButtonText: '确认',
      saveText: '',
      treeLoading: false,
      filterText: '',
      //组织结构
      organizeTreeList: [],
      defaultProps: {
        children: 'childrenOrg',
        label: 'orgName',
      },
      //tree回显选中
      checkedTreeList: [],
      //tree回显展开
      expandedList: [],
      orgAreaOptions: [],
      orgSchoolOptions: [],

      orgStatusOptions: [
        { label: '禁用', value: 0 },
        { label: '启用', value: 1 },
      ],
      orgRoleOptions: [],
      fileList: [],
      uploadMsg: '',
      uploadLoading: false,
      sysSubjectDicList: [], // 科目
    }
  },
  async created() {
    const { dataArr } = this.$store.state.public_data;
    await Promise.all(dataArr.map((e) => this.$store.dispatch("loadData", e)));

    // console.log(this.$store.state.public_data);
    this.sysSubjectDicList = this.$store.state.public_data.sysSubjectDicList
  },
  mounted() {
    this.init()
  },
  watch: {
    filterText(val) {
      this.$refs.organizeTree.filter(val)
    },
  },
  methods: {
    // 导入
    uploadBtn(fd) {
      this.uploadLoading = true
      // let fd = new FormData()
      // fd.append('file', item.file)
      // console.log('fd', fd)
      importExcelAdmin(fd)
        .then((res) => {
          this.uploadMsg = ''
          console.log('res', res)
          if (res.success) {
            // this.$message.success('导入成功');
            this.$message({
              showClose: true,
              message: '导入成功',
              type: 'success',
              duration: 10000,
            })
            this.init()
            this.$refs.uploadExcelCom.handleClose()
          } else {
            // this.$message.warning(res.msg)
            this.uploadMsg = res.msg
            this.$refs.uploadExcelCom.clearFile()
          }
          this.uploadLoading = false
        })
        .catch((err) => {
          this.uploadLoading = false
        })
    },
    // 下载excel模板
    downloadBtn() {
      downloadAdmin().then((res) => {
        console.log('下载excel模板', res)
        if (res.success) {
          this.$message.success('下载成功')
          window.open(this.$apiAddress(res.data))
        } else {
          this.$message.error('下载失败：' + res.msg)
        }
      })
    },
    //初始化用户信息
    init() {
      this.loading = true
      // console.log(this.$pinyin.getFullChars('12管理员3'));
      getUserListApi(this.page.pageIndex, this.page.pageSize, this.queryForm)
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.userList = res.data
            this.page.totalSize = res.total
            if (this.orgAreaOptions.length <= 0) {
              this.getOrgAreaList()
            }
            if (this.orgRoleOptions.length <= 0) {
              this.getOrgRoleList()
            }
          } else {
            this.msgError(res.msg)
          }
        })
        .catch((err) => {
          this.loading = false
          this.msgError(err)
        })
    },
    //获取筛选区县列表
    getOrgAreaList() {
      getOrgAreaListApi()
        .then((res) => {
          if (res.success) {
            this.orgAreaOptions = []
            res.data.childrenOrg.forEach((item) => {
              this.orgAreaOptions.push(item)
            })
          }
        })
        .catch(() => {})
    },
    //获取筛选角色列表
    getOrgRoleList() {
      getOrgRoleListApi()
        .then((res) => {
          if (res.success) {
            this.orgRoleOptions = []
            res.data.forEach((item) => {
              this.orgRoleOptions.push(item)
            })
          }
        })
        .catch(() => {})
    },
    //添加|编辑|查看用户
    edit(text, row) {
      if (!isEmpty(text)) {
        this.saveText = text
      }
      this.title = ''
      this.dialogVisible = true
      this.submitFormDisabled = false
      this.confirmDisabled = false
      //获取对话框tree data
      getOrganizeListApi(0, 1)
        .then((res) => {
          if (res.success) {
            this.organizeTreeList = []
            this.organizeTreeList.push(res.data)
            this.expandedList = [10019005]
            if (text == 'add') {
              this.title = '添加用户'
            } else if (text == 'edit') {
              this.title = '编辑用户'
              if (isEmpty(row)) {
                return
              }
              this.updateUser = row
              this.getUser(row)
            } else {
              this.title = '查看用户'
              this.submitFormDisabled = true
              this.confirmDisabled = true
              if (isEmpty(row)) {
                return
              }
              this.getUser(row)
            }
          }
        })
        .catch(() => {})
    },
    getUser(row) {
      if (isEmpty(row.userId)) {
        return
      }
      //获取用户详情
      getUserApi(row.userId)
        .then((res) => {
          if (res.success) {
            this.submitForm = JSON.parse(JSON.stringify(res.data))
            this.$set(this.submitForm, 'roleIdList', [])
            this.$set(this.submitForm, 'sysOrgDTOList', [])
            if (res.data.roleList.length > 0) {
              res.data.roleList.forEach((item) => {
                this.submitForm.roleIdList.push(item.sysAuthRoleId)
                this.$set(
                  this.submitForm,
                  'roleIdList',
                  this.submitForm.roleIdList
                )
              })
            }
            this.checkedTreeList = []
            this.expandedList = []
            this.selectValue = []
            this.selectedValue = []
            res.data.sysOrgDTOList.forEach((item) => {
              this.$set(
                this.submitForm,
                'sysOrgDTOList',
                res.data.sysOrgDTOList
              )
              this.checkedTreeList.push(item.orgId)
              this.expandedList.push(item.orgId)
              this.selectValue.push(item.orgName)
              this.selectedValue.push(item)
            })
            this.$nextTick(() => {
              let list = []
              let list2 = list.concat(this.$refs.organizeTree.getCheckedNodes())
              let listReturn = list2.concat(
                this.$refs.organizeTree.getHalfCheckedNodes()
              )
              this.selectedSysOrgDTOList = []
              this.selectedSysOrgDTOList = listReturn
            })
            // // 自动展开下拉框
            // this.$refs.selectFeature.toggleMenu();
          } else {
            this.msgError('查询错误')
          }
        })
        .catch(() => {})
    },
    //提交后台保存数据
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.$set(this.submitForm, 'sysOrgDTOList', this.selectedSysOrgDTOList);
          console.log(this.selectedSysOrgDTOList)
          this.submitForm.sysOrgDTOList = []
          this.submitForm.sysOrgDTOList = this.selectedSysOrgDTOList
          // console.log(this.submitForm,"***")
          if (this.saveText == 'add') {
            const loading = this.globalLoading('正在保存...')
            this.confirmLoading = true
            addUserApi(this.submitForm)
              .then((res) => {
                loading.close()
                this.confirmLoading = false
                if (res.success) {
                  this.msgSuccess('添加成功')
                  //置空防止数据污染
                  this.clearForm('submitForm')
                  this.dialogVisible = false
                  this.init()
                } else {
                  this.msgError(res.msg)
                }
              })
              .catch(() => {
                this.dialogVisible = false
                loading.close()
              })
          } else if (this.saveText == 'edit') {
            const loading = this.globalLoading('正在保存...')
            this.confirmLoading = true
            this.submitForm.userId = this.updateUser.userId
            updateUserApi(this.submitForm)
              .then((res) => {
                loading.close()
                this.confirmLoading = false
                if (res.success) {
                  this.msgSuccess('修改成功')
                  this.clearForm('submitForm')
                  this.dialogVisible = false
                  this.init()
                } else {
                  this.msgError(res.msg)
                }
              })
              .catch(() => {
                loading.close()
              })
          }
        } else {
          return false
        }
      })
    },
    //重置密码
    resetUserPass(row) {
      if (isEmpty(row)) {
        return
      }
      if (isEmpty(row.userId)) {
        return
      }
      if (!isEmpty(row.status)) {
        if (row.status == 0) {
          this.msgWarning(`账号${row.account}已被禁用,无法重置密码`)
          return
        }
      }
      this.globalMessageBox(
        `确认要重置密码吗?`,
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          const loading = this.globalLoading('正在重置...')
          resetUserPassApi(row.userId)
            .then((res) => {
              loading.close()
              if (res.success) {
                this.msgSuccess('已重置为初始密码')
              } else {
                this.msgError('重置失败')
              }
            })
            .catch(() => {
              loading.close()
            })
        })
        .catch(() => {})
    },
    //禁用
    disabled(row) {
      if (isEmpty(row)) {
        return
      }
      if (isEmpty(row.userId)) {
        return
      }
      let type = 'warning'
      if (row.status == 0) {
        type = 'success'
      }
      const statusDes = row.status == 0 ? '启用' : '禁用'
      this.globalMessageBox(
        `确认要${statusDes}该用户吗?`,
        '提示',
        '确定',
        '取消',
        type
      )
        .then(() => {
          const loading = this.globalLoading(`正在${statusDes}...`)
          let _status = 1
          if (row.status == 1) {
            _status = 0
          }
          updateUserStatusApi(row.userId, _status)
            .then((res) => {
              loading.close()
              if (res.success) {
                this.msgSuccess(`${statusDes}成功`)
                this.init()
              } else {
                this.msgError(`${statusDes}失败`)
              }
            })
            .catch(() => {
              loading.close()
            })
        })
        .catch(() => {})
    },
    //删除
    remove(row) {
      if (isEmpty(row)) {
        return
      }
      if (!isEmpty(row.status)) {
        if (row.status == 0) {
          this.msgWarning(`账号${row.account}已被禁用,无法删除`)
          return
        }
      }
      if (isEmpty(row.userId)) {
        return
      }
      this.globalMessageBox(
        '确认要删除该用户吗?',
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          let idList = []
          idList.push(row.userId)
          if (idList.length <= 0) {
            return
          }
          const loading = this.globalLoading('正在删除...')
          removeUserApi(idList)
            .then((res) => {
              loading.close()
              if (res.success) {
                this.msgSuccess('删除成功')
                this.reloadInit()
              } else {
                this.msgError(res.msg)
              }
            })
            .catch(() => {
              loading.close()
            })
        })
        .catch(() => {})
    },
    //批量删除
    batchRemove() {
      if (this.selectedList.length <= 0) {
        this.msgWarning('请勾选要删除的用户')
        return
      }
      let allUserList = []
      let idList = []
      let unCheckedList = []
      this.selectedList.forEach((item) => {
        if (isEmpty(item)) {
          return
        }
        if (isEmpty(item.userId)) {
          return
        }
        allUserList.push(item.userId)
        if (isEmpty(item.status)) {
          return
        }
        if (item.status == 0) {
          unCheckedList.push(item.account)
          return
        }
        idList.push(item.userId)
      })

      this.globalMessageBox(
        `确认要删除已勾选(${allUserList.length}项)用户吗?`,
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          if (unCheckedList && unCheckedList.length > 0) {
            let returnInfo = ''
            unCheckedList.forEach((item, index) => {
              returnInfo += item
              if (index < unCheckedList.length - 1) {
                returnInfo += ', '
              }
            })
            this.msgWarning(
              `账号${returnInfo}已被禁用,删除${unCheckedList.length}个失败`
            )
            //清空table已选项
            this.$refs.userTable.clearSelection()
          }
          if (idList.length <= 0) {
            return
          }
          const loading = this.globalLoading('正在删除...')
          removeUserApi(idList)
            .then((res) => {
              loading.close()
              if (res.success) {
                if (idList.length <= 0) {
                  return
                }
                this.msgSuccess('删除成功')
                this.reloadInit()
              } else {
                if (res.code != 500) {
                  this.msgWarning(`参加考试的${res.msg}`)
                }
              }
            })
            .catch(() => {
              loading.close()
            })
        })
        .catch(() => {})
    },
    //刷新
    refresh() {
      this.resetQueryParam()
      this.init()
    },
    resetQueryParam() {
      this.queryForm.queryName = ''
      this.queryForm.queryAccount = ''
      this.queryForm.querySysOrgAreaId = ''
      this.queryForm.querySysOrgSchoolId = ''
      this.queryForm.querySubjectType = ''
      this.queryForm.querySysAuthRoleId = ''
      this.queryForm.status = ''
    },
    //当选择项发生变化时会触发
    handleSelectionChange(val) {
      this.selectedList = []
      this.selectedList = val
    },
    //currentPage 改变时会触发,如第一页切换到第二页
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.init()
    },
    //pageSize 改变时会触发,如每页10条切换到每页15条
    handleSizeChange(val) {
      this.page.pageSize = val
      this.init()
    },
    //弹出框右上角×触发
    handleClose() {
      this.dialogVisible = false
      this.submitFormDisabled = false
      this.clearForm('submitForm')
    },
    cancel(formName) {
      this.dialogVisible = false
      this.submitFormDisabled = false
      this.clearForm(formName)
    },
    closed() {
      this.dialogVisible = false
      this.isDisabled = false
      this.filterText = ''
      this.clearForm('submitForm')
    },
    clearForm(formName) {
      //清空表单并重置校验
      this.$refs[formName].resetFields()
      this.submitForm = this.$options.data().submitForm
      //删除所有tree选中节点
      this.$refs.organizeTree.setCheckedKeys([])
      this.checkedTreeList = []
      this.expandedList = []
    },
    handleCheckedGroupChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length
    },
    //多选框内容发生改变时触发
    handleCheckedChange(value) {
      this.submitForm.roleIdList = []
      this.submitForm.roleIdList = value
      let checkedCount = value.length
      this.checkAll = checkedCount === this.orgRoleOptions.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.orgRoleOptions.length
    },
    nickNameChange() {
      this.reloadInit()
    },
    accountChange() {
      this.reloadInit()
    },
    orgAreaChange(orgId) {
      this.queryForm.querySysOrgAreaId = orgId
      this.queryForm.querySysOrgSchoolId = ''
      this.getOrgSchoolList(orgId)
      this.reloadInit()
    },
    //获取筛选学校列表
    getOrgSchoolList(orgId) {
      if (
        isEmpty(this.queryForm.querySysOrgAreaId) ||
        !this.queryForm.querySysOrgAreaId
      ) {
        return
      }
      getOrgSchoolListApi(0, 1, orgId)
        .then((res) => {
          if (res.success) {
            this.orgSchoolOptions = []
            this.orgSchoolOptions = res.data.childrenOrg
          }
        })
        .catch(() => {})
    },
    orgSchoolChange() {
      this.reloadInit()
    },
    orgSubjectChange() {
      this.reloadInit()
    },
    orgRoleChange() {
      this.reloadInit()
    },
    orgStatusChange() {
      this.reloadInit()
    },
    reloadInit() {
      this.page.pageIndex = 1
      this.init()
    },
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'roleList':
          if (row.roleList.length <= 0) {
            return '未知'
          } else {
            let appendReturn = ''
            row.roleList.forEach((item, index) => {
              appendReturn += item.name
              if (index < row.roleList.length - 1) {
                appendReturn += ','
              }
            })
            return appendReturn
          }
      }
    },

    //--------------树状图处理--------------
    //过滤数据
    filterNode(value, data) {
      if (!value) return true
      return data.orgName.indexOf(value) !== -1
    },
    checkOrganizeChange() {
      let list = []
      let list2 = list.concat(this.$refs.organizeTree.getCheckedNodes())
      let listReturn = list2.concat(
        this.$refs.organizeTree.getHalfCheckedNodes()
      )
      this.selectedSysOrgDTOList = []
      this.selectedSysOrgDTOList = listReturn
    },
    selectFeatureChange(event) {
      let arrNew = []
      this.selectedValue.forEach((item) => {
        event.forEach((eventItem) => {
          if (eventItem == item.orgName) {
            arrNew.push(item)
          }
        })
      })
      this.$refs.organizeTree.setCheckedNodes(arrNew)
    },
    handleCheckChange(data, checked, indeterminate) {
      let res = this.$refs.organizeTree.getCheckedNodes(true, true)
      let arrLabel = []
      let arr = []
      res.forEach((item) => {
        arrLabel.push(item.orgName)
        arr.push(item)
      })
      this.selectValue = arrLabel
      this.selectedValue = arr

      let list = []
      let list2 = list.concat(this.$refs.organizeTree.getCheckedNodes())
      let listReturn = list2.concat(
        this.$refs.organizeTree.getHalfCheckedNodes()
      )
      this.selectedSysOrgDTOList = []
      this.selectedSysOrgDTOList = listReturn
    },

    //--------------处理文件上传--------------
    //上传文件之前的钩子
    handleBeforeUpload(file) {
      // const uploadTypes = ['jpg', 'png', 'doc', 'docx', 'xlsx', 'zip', 'rar', 'pdf'];
      const uploadTypes = ['avi']
      const fileType = file.name.replace(/.+\./, '')
      if (uploadTypes.indexOf(fileType.toLowerCase()) === -1) {
        this.msgError('文件格式错误,请重新上传!')
        return false
      }
      const uploadFileLimit = 2
      const isLt2M = file.size / 1024 / 1024 < uploadFileLimit
      if (!isLt2M) {
        this.msgError(`上传文件大小不能超过 ${uploadFileLimit}MB!`)
        return false
      }
    },
    //点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      console.log(file)
    },
    handleRemove() {},
    handleExceed() {},
    clearFiles() {
      this.$refs.uploadRef.clearFiles()
    },
  },
}
</script>

<style lang="scss" scoped>
table > thead > tr > th:last-child > div {
  text-align: center;
}

.input-content,
.select-content > .el-form-item__content > .el-select,
.treeContent {
  width: 250px;
}

::v-deep .select-tree-content .el-input__inner {
  width: 250px;
}

.treeContent {
  padding-right: 0px;
  border-right: 0px solid #ccc;
}

.customClassNotify {
  word-wrap: break-word !important;
  word-break: break-all !important;
}

.el-checkbox-group {
  margin: -40px 0 0 92px;
}

.setstyle {
  min-height: 250px;
  padding: 0 !important;
  margin: 0;
  margin-top: -6px;
  overflow: auto;
  cursor: default !important;
}

// #user-content .el-form-item__label {
//   padding: 0;
//   text-align: center;
//   background-color: #dfe6ec;
// }

#tree_box {
  border-radius: 4px;
  border: 1px solid gainsboro;
  // border-right: 0;
}

::v-deep #tree_box .el-tree {
  width: 250px;
  height: 220px;
  overflow-y: auto;
}
</style>
